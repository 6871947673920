import classes from "./Card.module.css";
const Card = (props) => {
  return (
    <div className={`card ${props.style}`}>
      {props.children}
    </div>
  );
};

export default Card;
